<template>
  <div>
    <div class="item-box">
      <div class="row-box">
        <title-item text="间断时间"></title-item>
        <div class="time-box">
          <div>{{info.begDate}}</div><span></span><div>{{info.endDate}}</div>
        </div>
      </div>

      <div class="row-box">
        <title-item text="缴费月基数"></title-item>
        <van-field
            style="width:60%"
            readonly
            clickable
            :value="info.baseFee"
            @touchstart.native.stop="numberShow = true"
        />
        <span class="unit">元</span>
      </div>
      <van-divider />

    </div>

    <van-number-keyboard
        v-model="info.baseFee"
        :show="numberShow"
        :maxlength="6"
        @blur="numberShow = false"
    />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, NumberKeyboard, Field, } from 'vant'
import TitleItem from '@/views/calc/TitleItem'
export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    TitleItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [NumberKeyboard.name]: NumberKeyboard,
    [Field.name]: Field,
  },
  data() {
    return {
      numberShow: false,
      params: {
        baseFee: ''
      }
    }
  }
}
</script>

<style scoped>

.item-box >>> .van-divider {
  margin: 0;
}

.item-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.row-box .unit {
  font-size: 14px;
  font-weight: normal;
  position: relative;
  top: 1px;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-cell::after {
  border: none;
}

.row-box >>> .van-field__control {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.time-box {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
  align-items: center;
  justify-content: space-between;
}

.time-box span {
  width: 16px;
  height: 2px;
  background: #999999;
  border-radius: 1px;
  margin:  0 4px;
}
</style>