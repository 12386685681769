<template>
  <div>
    <calc-page>
      <template slot="topBox">
        <round-container>
          <company-divide-item
            v-for="(item, idx) in list"
            :key="idx"
            :info="item"
          ></company-divide-item>
        </round-container>
      </template>
      <template slot="footerBtn">
        <div class="btn-box" @click="onCalc">开始计算</div>
      </template>
    </calc-page>
  </div>
</template>

<script>
import { calcCompanyArrears } from "@/api/tool";
import to from "@/utils/to";
import CompanyDivideItem from "./CompanyDivideItem";
import RoundContainer from "@/views/calc/components/RoundContainer";
import CalcPage from "@/components/CalcPage";
import { mapGetters } from "vuex";
export default {
  components: {
    CalcPage,
    CompanyDivideItem,
    RoundContainer,
  },
  data() {
    return {};
  },
  computed: {
    list() {
      return this.getAnnuityCompany().dividedGapList;
    },
  },
  methods: {
    async onCalc() {
      const arrear = this.list;
      for (let i = 0; i < arrear.length; i++) {
        const item = arrear[i];
        if (item.baseFee === "") {
          this.$showInfo(
            item.begDate + "--" + item.endDate + "，未填写缴费基数"
          );
          return;
        }
      }

      let data = [];
      arrear.forEach((item) => {
        let ele = {};
        ele.begDate = item.begDate + "/01";
        ele.endDate = item.endDate + "/01";
        ele.baseFee = item.baseFee;

        data.push(ele);
      });

      this.$showLoading("计算中...");
      let [err, res] = await to(calcCompanyArrears(data));
      this.$hideLoading();

      if (err) {
        this.$toast.fail("计算失败");
        return;
      }

      this.$router.push({
        name: "PensionSupplementCompanyResultPage",
        query: { res: JSON.stringify(res) },
      });
    },
    ...mapGetters("calc", ["getAnnuityCompany"]),
  },
};
</script>

<style scoped>
.btn-box {
  position: fixed;
  bottom: 10px;
  background:#658CF1;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 136px;
}
</style>